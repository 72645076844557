import { isActive } from './Scheduler';
import tasks from './tasks.json';

const STORAGE_KEY_VERSION = '1.0.0'

interface Task {
    name: string
    completed: boolean
}

export function save(task: Task): void {
    const key = STORAGE_KEY_VERSION + task.name
    const value = task.completed ? 'done' : 'todo'
    localStorage.setItem(key, value)
}

export function get(name: string): Task {
    const key = STORAGE_KEY_VERSION + name
    const value = localStorage.getItem(key)

    return {
        name,
        completed: value === 'done'
    }
}

export function all(): Task[] {
    return tasks.filter(task => isActive(task)).map(task => get(task.name))
}

export function reset(): void {
    localStorage.clear()
}
