import './App.css';
import { all, reset } from './Storage';
import Task from './Task';

function App() {
  const resetAll = () => {
    reset()
    window.location.reload()
  }

  return (
    <div className="App">
      <header className="App-header">
        <button onClick={resetAll}>reset</button>
        <ul>
          {all().map(task => <Task key={task.name} name={task.name} />)}
        </ul>
      </header>
    </div>
  );
}

export default App;
