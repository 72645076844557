interface Task {
    name: string
    schedule?: string
}

export function isActive(task: Task): boolean {
    if (task.schedule === "even") {
        return new Date().getDate() % 2 === 0
    }

    return true
}
