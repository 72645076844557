import { useEffect, useState } from "react"
import { get, save } from "./Storage"

interface Props {
    name: string
}

const Task: React.FC<Props> = ({ name }) => {
    const [completed, setCompleted] = useState<boolean>(false)

    const complete = () => {
        const toggle = !completed
        save({ name, completed: toggle })
        setCompleted(toggle)
    }

    useEffect(() => {
        const task = get(name)
        setCompleted(task.completed)
    }, [name])

    const className = completed ? 'completed' : 'todo'

    return (
        <li className={className} onClick={complete}>{name}</li>
    )
}

export default Task
